import locale from "element-ui/lib/locale";
import Cookie from "js-cookie";
import Vue from "vue";
import VueI18n from "vue-i18n";

import customEN from "@/locales/lang/en"; // 自定义语言包
import customCN from "@/locales/lang/zh-cn"; // 自定义语言包
import customTW from "@/locales/lang/zh-tw"; // 自定义语言包
import enLocale from "element-ui/lib/locale/lang/en"; // 英文
import cnLocale from "element-ui/lib/locale/lang/zh-CN"; // 中文简体
import twLocale from "element-ui/lib/locale/lang/zh-TW"; // 中文繁体

Vue.use(VueI18n);

export default new VueI18n({
  locale: localStorage.getItem("language") || "en", // 指的是当前的多语言的类型 随意定义的字符串
  messages: {
    en: {
      // 语言包
      ...enLocale,
      ...customEN,
    },
    "zh-cn": {
      // 语言包  elementUI 语言包  + 自定义语言包
      ...cnLocale,
      ...customCN,
    },
    "zh-tw": {
      ...twLocale,
      ...customTW,
    },
  }, // 指的是当前的语言包
});
