<template>
  <el-popover
    trigger="click"
    v-model="visible"
    placement="bottom"
    popper-class="notification-popover"
  >
    <span class="btn-nav-icon" slot="reference">
      <svg-icon style="width: 17px; height: 19px" icon-class="topNav-bell" />
      <div class="bell-dot" v-if="notReadCount > 0">
        {{ notReadCount }}
      </div>
    </span>
    <div class="header">
      <span>{{ $t("notification") }}</span>
      <div class="right">
        <div v-if="notReadCount > 0">
          <i class="el-icon-loading" v-if="markLoading" />
          <svg-icon v-if="!markLoading" icon-class="checkmark-done" />
          <span @click="markAll">{{ $t("markAsAllRead") }}</span>
        </div>
        <i class="el-icon-setting" @click="goSetting" />
      </div>
    </div>
    <div class="main" v-if="datas && datas?.length">
      <scroll-loader
        class="content-wrap"
        @handleQuery="handleQuery"
        :disableObserve="disableObserve"
        :loading="loading"
      >
        <notification-item
          v-for="item in datas"
          :key="item?.profile_user_notification_id"
          :data="item"
          :profile_id="profileId"
          @decreaseNotReadCount="decreaseNotReadCount"
          :statusSlugMap="statusSlugMap"
          @close="visible = false"
          :read_ids="read_ids"
        />
      </scroll-loader>
      <div class="footer" @click="goNotification">View All</div>
    </div>

    <div class="notification-empty" v-else-if="loading === false">
      <svg-icon
        icon-class="none-notification"
        class="notification-empty-icon"
      />
      <p class="notification-empty-title">
        {{ $t("none-notification-title") }}
      </p>
      <p class="notification-empty-desc">
        {{ $t("none-notification-description") }}
      </p>
    </div>
    <div v-else class="loading">
      <i class="el-icon-loading"></i>
    </div>
  </el-popover>
</template>

<script>
import { getNotificationList, updateNotification } from "@/api/notification";
import { mapActions, mapMutations, mapState } from "vuex";
import Loading from "../Loading.vue";
import ScrollLoader from "../scroll-loader/scroll-loader.vue";
import notificationItem from "./notification-item.vue";
export default {
  components: { notificationItem, ScrollLoader, Loading },
  name: "notification",
  data() {
    return {
      markLoading: false,
      page: 1,
      disableObserve: false,
      loading: false,
      datas: [],
      pagination: {},
      notReadCount: 0,
      visible: false,
      timer: undefined,
    };
  },
  computed: {
    ...mapState("services", ["statusSlugMap"]),
    ...mapState("notification", ["read_ids"]),
    ...mapState("user", ["systemConfig"]),
    profileId() {
      return this.$store.state.user.currentId.profile_id;
    },
    delay() {
      return this.systemConfig.default_notification_bar_delay_second * 1000;
    },
  },
  watch: {
    profileId() {
      this.reset();
    },
    read_ids(value) {
      this.notReadCount = this.notReadCount - value.length;
    },
  },
  methods: {
    ...mapActions("services", ["fetchStatusList"]),
    ...mapMutations("notification", ["setReadIds"]),
    reset() {
      this.page = 1;
      this.datas = [];
      this.notReadCount = 0;
      this.pagination = {};
      this.disableObserve = false;
      this.queryList(1);
    },
    async markAll() {
      if (this.notReadCount === 0) {
        this.$message.warning("No unread notification");
        return;
      }
      try {
        const ids = this.datas
          .filter((item) => !item.is_read)
          .map((item) => item.profile_user_notification_id);
        if (!ids.length) {
          this.$message.warning(
            "No unread notification, please scroll to load more notifications"
          );
          return;
        }
        await updateNotification({
          profile_user_notifications: ids,
          is_read: true,
          profile_id: this.profileId,
        });
        this.setReadIds(ids);
        this.$message.success("Marked as all read");
      } finally {
        this.markLoading = false;
      }
    },
    goNotification() {
      this.$router.push({ name: "notification" });
      this.visible = false;
    },
    goSetting() {
      this.$router.push({ name: "notification", query: { tab: "2" } });
      this.visible = false;
    },
    decreaseNotReadCount() {
      this.notReadCount = this.notReadCount - 1;
    },
    async handleQuery() {
      this.page = this.page + 1;
      await this.queryList(this.page);
    },
    async queryList(page) {
      if (this.timer) {
        return;
      }
      try {
        this.loading = true;
        const res = await getNotificationList({
          profile_id: this.profileId,
          page: page,
        });
        const { metadata, pagination, profile_user_notifications } =
          res.data || {};
        if (page === 1) {
          this.notReadCount = metadata.total_unread;
          this.pagination = pagination;
        }

        if (
          page === this.pagination?.total_pages ||
          !profile_user_notifications ||
          !profile_user_notifications.length
        ) {
          this.disableObserve = true;
        }

        if (page > 1) {
          this.timer = setTimeout(() => {
            this.loading = false;
            this.datas = [...this.datas, ...profile_user_notifications];
            clearTimeout(this.timer);
            this.timer = undefined;
          }, this.delay);
        } else {
          this.loading = false;
          this.datas = [...this.datas, ...profile_user_notifications];
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
      } finally {
      }
    },
  },
  mounted() {
    this.fetchStatusList();
    this.queryList(1);
  },
};
</script>

<style lang="scss" scoped>
.btn-nav-icon {
  position: relative;
  .bell-dot {
    position: absolute;
    top: -8px;
    left: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 16px;
    border-radius: 50%;
    background: #ff2727;
    font-family: Lato;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0.03em;
    text-align: left;
    color: #ffffff;
  }
}
</style>
