import Cookies from "js-cookie";

const TokenKey = "pubrio-token";
const Apikey = "pubrio-apikey";
const Account = "pubrio-account";
const UserInfo = "pubrio-user-info";
const timeKey = "pubrio-timestamp-key";
const currentId = "pubrio-current-id";
const subList = "pubrio-sub-list";

export function getToken() {
  return Cookies.get(TokenKey);
}
export function getApikey() {
  return Cookies.get(Apikey);
}
export function getUserInfo() {
  return Cookies.get(UserInfo);
}
export function getAccount() {
  return Cookies.get(Account);
}
export function getCurrentId() {
  return Cookies.get(currentId);
}
export function getSubList() {
  return Cookies.get(subList);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token);
}
export function setApikey(apiKey) {
  return Cookies.set(Apikey, apiKey);
}
export function setUserInfo(data) {
  return Cookies.set(UserInfo, data || "");
}
export function setAccount(account) {
  return Cookies.set(Account, account);
}
export function setCurrentId(id) {
  return Cookies.set(currentId, id);
}
export function setSubList(list) {
  return Cookies.set(subList, list);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}
export function removeApikey() {
  return Cookies.remove(Apikey);
}
export function removeUserInfo() {
  return Cookies.remove(UserInfo);
}
export function removeAccount() {
  return Cookies.remove(Account);
}
export function removeCurrentId() {
  return Cookies.remove(currentId);
}
export function removeSubList() {
  return Cookies.remove(subList);
}

// 获取时间戳
export function getTimeStamp() {
  return Cookies.get(timeKey);
}
// 设置时间戳
export function setTimeStamp() {
  Cookies.set(timeKey, Date.now());
}
