import request from '@/utils/request';

export function getPriceConfig(){
  return request({
      method: 'get',
      url:'/frontend',
  })
};

export function getUsageCharge(data) {
  return request({
      method: 'post',
      url:'/subscriptions/usage/charge',
      data,
  })
};

export function upgradePlan(data) {
  return request({
    method: 'post',
    url:'/subscriptions/upgrade',
    data
  })
}

export function estimateUpgrade(data) {
  return request({
    method: 'post',
    url:'/subscriptions/upgrade/estimate',
    data
  })
}


export function getSubscriptions(data) {
  return request({
    method: 'post',
    url:'/subscriptions',
    data
  })
}


