<template>
  <el-dialog
    :visible.sync="paymentSecurityDialog"
    :modal-append-to-body="false"
    :before-close="handleClose"
    width="519px"
    custom-class="pay-tip-dialog"
    :close-on-click-modal="false"
  >
    <div class="pay-dialog-close-wrapper" @click="handleClose">
      <svg-icon icon-class="pay-dialog-close" class="pay-dialog-close" />
    </div>
    <div class="title">
      <svg-icon icon-class="lead-limit-tip" class="icon-danger" />
      <span>Credit Running Low!</span>
    </div>
    <div>
      <div class="content1">Your credit is running low, and we couldn’t process an auto top-up.</div>
      <div class="content2">Please manually top up your credit to avoid interruptions to your service.</div>
    </div>
    <div class="btns-wrapper">
      <el-button @click="handleClose" class="btn-group-1">{{ $t('Cancel') }}</el-button>
      <el-button @click="topUpNow" class="btn-group-2">
        Top-up Now
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapState('common', ['paymentSecurityDialog']),
  },
  methods: {
    ...mapMutations('common', ['setPaymentSecurityDialog']),
    handleClose() {
      this.setPaymentSecurityDialog(false);
    },
    topUpNow() {
      this.setPaymentSecurityDialog(false);
      this.$router.push({ path: "/settings/current-usage?#topup" });
    }
  }
}
</script>
