<template>
  <el-dialog
    :visible.sync="payDialog"
    :modal-append-to-body="false"
    :before-close="handlePayDialogClose"
    width="519px"
    custom-class="pay-tip-dialog"
  >
    <div class="pay-dialog-close-wrapper" @click="handlePayDialogClose">
      <svg-icon icon-class="pay-dialog-close" class="pay-dialog-close" />
    </div>
    <div class="title">
      <svg-icon icon-class="lead-limit-tip-blue" class="icon-danger" />
      <span>Payment Method Required</span>
    </div>
    <div>
      <div class="content1">It looks like you don’t have a payment method linked to your account.</div>
      <div class="content2">Please add your payment method to continue.</div>
    </div>
    <div class="btns-wrapper">
      <el-button class="btn-group-1" @click="handlePayDialogClose">
        {{ $t("Cancel") }}
      </el-button>
      <el-button class="btn-group-2" @click="addCard"> Add Now </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
  computed: {
    ...mapState('common', ['payDialog'])
  },
  methods: {
    ...mapMutations('common', ['setPayDialog']),
    addCard() {
      this.$router.push({name: 'billing'});
      this.setPayDialog(false);
    },
    handlePayDialogClose() {
      this.setPayDialog(false);
      // this.$emit('update:visible', false)
    }
  },
}
</script>

<style scoped lang="scss">

</style>
