<template>
<div id="nav">
  <el-menu router :default-active="$route.path"
           class="el-menu-demo"
           mode="horizontal">
 <!-- 
  <el-menu-item index="/home"
                popper-class="menu1"
                :popper-append-to-body="false">
      <el-button class="btn-nav">
        <svg-icon class="svgicon" icon-class="home" style="width:16px;height:18px"></svg-icon>
        <span>{{$t("Home") }}</span>
      </el-button>
    </el-menu-item> 
  -->

    <el-menu-item v-if="enable_search" index="/search/people"
                  :class=" searchActive ?'is-active':'' ">
      <el-button class="btn-nav" >
       <svg-icon class="svg-icon" icon-class="nav-lead" ></svg-icon>
        <span>{{$t("Lead") }}</span>
      </el-button>

      <div class="submenu" >
        <router-link :to="{name:'people'}">
          <div class="submenu-item">
            <div class="icon">
              <svg-icon icon-class="user"/>
              <div class="title">{{ $t('Prospects') }}</div>
            </div>
            <div class="texts">
              {{ $t('filterYourSearchToFindHighlySpecific') }}<br/>
              {{ $t('groupsOfTargetedLeads') }}
            </div>
        </div>
        </router-link>
        <a>
          <div class="submenu-item" @click.stop="handleRoute('company')">
            <div class="icon">
              <svg-icon style="width:22px" icon-class="filter-companies"/>
              <div class="title">{{ $t('companies') }}</div>
            </div>
            <div class="texts">
              {{ $t('filterYourSearchToFindPreciselyTargeted') }}<br/>
              {{ $t('listsOfCompanies') }}
            </div>
          </div>
        </a>
        <router-link :to="{name:'people'}">
          <div class="submenu-item">
            <div class="icon">
              <svg-icon icon-class="nav-enrich"/>
              <div class="title">Enrich</div>
            </div>
            <div class="texts">
              Instantly enhance your data by adding valuable insights and key details from various sources
            </div>
        </div>
        </router-link>
      </div>
    </el-menu-item>


    <el-menu-item v-if="enable_intent" index="/intent-signals/intent"
                  :class=" intentActive ?'is-active':'' ">
      <el-button class="btn-nav">
        <svg-icon class="svgicon" icon-class="cast" style="width:19px;height:16px" ></svg-icon>
        <span>{{$t("Intent_Signals") }}</span>
      </el-button>
      <div class="submenu" style="left:-70px">
          <router-link :to="{name:'intent'}">
            <div class="submenu-item">
              <div class="icon">
                <svg-icon icon-class="Api"/>
                <div class="title"> {{$t("Website_Intent") }}</div>
              </div>
              <div class="texts">
                <div class="description">{{ $t('transformAnonymousWebsiteTrafficInto') }}<br/>
                  {{ $t('b2bLeadsWithRealtimeIdentification') }}
                </div>
              </div>
            </div>
          </router-link>
          <a>
            <div class="submenu-item" @click.stop="handleRoute('market')">
              <div class="icon">
                <svg-icon icon-class="Crm"/>
                <div class="title">{{$t("Market_Intent") }}</div>
              </div>
              <div class="texts">
                <div class="description">{{ $t('discoverAndConnectWithPotentialBuyers') }}<br/>
                  {{ $t('activelySeekingYourSolutionToday') }}
                </div>
              </div>
            </div>
          </a>
        </div>
    </el-menu-item>

    <el-menu-item v-if="enable_sequence" index="/sequence" :class=" sequenceActive ?'is-active':'' ">
      <el-button class="btn-nav">
        <svg-icon class="svg-icon" icon-class="Sent" />
        <span>{{$t("Sequence") }}</span>
      </el-button>
    </el-menu-item>

     <!-- <el-menu-item v-if="enable_tool" index="/tools"  :class=" toolActive ?'is-active':'' ">
        <el-button class="btn-nav">
          <svg-icon class="svgicon" icon-class="server" style="width:20px;height:20px"></svg-icon>
          <span>{{$t("Tools") }}</span>
        </el-button>
    </el-menu-item> -->

    <el-menu-item v-if="enable_service" index="/services" :class=" serviceActive ?'is-active':'' ">
        <el-button class="btn-nav">
          <svg-icon class="svg-icon" icon-class="server" />
          <span>{{$t("Services") }}</span>
        </el-button>
    </el-menu-item>
  </el-menu>



</div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "topNav",
  data(){
    return{
      enable_search: false,
      enable_intent: false,
      enable_tool: false,
      enable_service: false,
      enable_sequence: false,
    }
  },
  mounted() {
    this.getProfile({ profile_id: this.currentId.profile_id });
    this.getConfig({ profile_id: this.currentId.profile_id });
    !localStorage.getItem("pubrio-profile") && this.getUserInfo(false)
  },
  watch: {
    systemConfig: {
      handler(val) {
        if (val && val.disabled_navigation_display) {
          const navigationDisplay = this.systemConfig.disabled_navigation_display;
          this.enable_search = !navigationDisplay.includes('search');
          this.enable_intent = !navigationDisplay.includes('intent_signals');
          this.enable_sequence = !navigationDisplay.includes('sequences');
          this.enable_tool = !navigationDisplay.includes('tools');
          this.enable_service = !navigationDisplay.includes("services");
        }
      },
      immediate:true,
    },
  },
  computed:{
    ...mapState('user',['systemConfig', 'currentId']),
    searchActive() {
      if (this.$route.path.indexOf('/search') > -1) {
        return true
      }
    },
    sequenceActive() {
      if (this.$route.path.indexOf('/sequence') > -1) {
        return true
      }
    },
    serviceActive() {
      if (this.$route.path.indexOf('/services') > -1) {
        return true
      }
    },
    toolActive() {
      if (this.$route.path.indexOf('/tools') > -1) {
        return true
      }
    },
    intentActive(){
      if (this.$route.path.indexOf('/intent-signals') > -1) {
        return true
      }
    }
  },
  methods:{
    ...mapActions('account', ['getProfile']),
    ...mapActions('user',['getConfig', 'getUserInfo']),
    handleCommand(command) {
      this.$store.commit('company/setMenuType', command);
    },
    tempMenuJump() {
      this.$router.push('/market')
    },
    handleRoute(route) {
      this.$router.push({name: route})
    }
  }
}
</script>

<style scoped lang="scss">
@media (max-width: 991px) {
  #nav{
    max-width: 300px;
    .btn-nav{
      padding: 0 5px;
    }
    .el-menu .el-menu-item .svg-icon{
      display: none;
    }
  };
}

#nav{
  display: flex;
  align-items: center;
  height:100%;
  ::v-deep .el-menu {
    display: flex!important;
    align-items: center;
    height:100%;
    .btn-nav {
      .svg-icon {
        width: 16px;
        height: 16px;
      }
    }
    .el-icon-arrow-down:before {
      content:''!important;
    }
    .el-menu-item{
      flex-shrink: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    .el-menu-item.is-active{
      border-bottom: 1px solid #fff;
      .btn-nav{
        background:$color-blue-2;
        color: $color-blue-4;
        border:0;
      }
    }
  }
}
.el-menu.el-menu--horizontal{
  border: 0;
}
.el-dropdown-menu__item, .el-menu-item{
  padding:0!important;
}
  .submenu{
    display: none;
    position: absolute;
    top:44px;
    width: 340px;
    left: -80px;
    border-radius: 8px;
    padding: 8px;
    box-shadow: 0 8px 16px 0 #2636990A;
    background: #fff;
  }
.el-menu-item:hover .submenu{
  display: block;
}


  .submenu-item{
    width: 324px;
    border-radius: 8px;
    padding: 16px;
    .icon{
      display: flex;
      margin-bottom: 8px;
      .svg-icon{
        margin-right: 10px;
        width: 16px;
        height: 16px;
      }
      .title {
        font-style: normal;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.45px;
        font-weight: 500;
        text-align: left;
        color:$color-black;
      }
    }
    .texts {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0.02em;
      color: $color-black;
      white-space: normal;
    }
  }
  .submenu-item:hover, .submenu-item.is-active{
    background: $color-blue-1;
  }
</style>
