import request from "@/utils/request";


/*---------------人员saved分组---------------*/
export function getPeopleGroup(data) {
    return request({
        url: '/saved/people_group',
        method: 'post',
        data
    })
}
/*---------------人员综合search---------------*/
export function searchPeopleList(data) {
    return request({
        url: '/people/search',
        method: 'post',
        data
    })
}
/*---------------人员管理级别---------------*/
export function getManagementLevels() {
    return request({
        url: '/management_levels',
        method: 'get',
    })
}
/*---------------人员部门---------------*/
export function getDepartments() {
    return request({
        url: '/departments/title',
        method: 'get',
    })
}
/*---------------部门Function---------------*/
export function getDepartmentFunction() {
    return request({
        url: '/departments/function',
        method: 'get',
    })
}
/*---------------人员name---------------*/
export function getNames(data) {
    return request({
        url: '/people/names',//还没有API
        method: 'post',
        data
    })
}

/*---------------进入人员个人详情页---------------*/
export function getPeopleDetail(data) {
    return request({
        url: '/people/lookup',
        method: 'post',
        data
    })
}
/*---------------人员表格请求Email地址---------------*/
export function getEmail(data) {
    return request({
        url: '/redeem/people',
        method: 'post',
        data
    })
}

export function batchRedeem(data) {
    return request({
        url: '/redeem/people/batch',
        method: 'post',
        data
    })
}

export function getbatchRedeemResult(data) {
    return request({
        url: '/redeem/people/batch/query',
        method: 'post',
        data
    })
}
/*---------------从group删除Item---------------*/
export function deletePeople(data) {
    return request({
        url: '/saved/people/delete',
        method: 'delete',
        data
    })
}
/*-----根据groups返回domain ID------*/
export function getDomainIdByGroup(data) {
    return request({
        method: 'post',
        url: '/people/select',
        data
    })
}
/*---------------export------------------*/
export function exportPeople(data) {
    return request({
        method: 'post',
        url: '/people/export',
        data,
        responseType: 'blob',
    })
}