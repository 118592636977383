export const notificationEnglish = {
  "notification-view-details": "View Details",
  "none-notification-title": "No Notifications Yet",
  "none-notification-description":
    "Looks like there's nothing new for now. Check back later for updates and notifications.",
  "notification-data-redemption-completed-people-message": `Total of {0} credits have been used to redeem {1} contacts.`,
  "notification-services-status-update-message": `Service request status changed to {0}`,
  "notification-receive-referral-credit-bonus-message":
    "{0} referral credits have been added, keep up the good work!",
  notification_read_less: "Read less",
  notification_read_more: "Read more",
  notification_column_name_content: 'Notifications',
  notification_column_name_date: 'Received On'
};

export const notificationSimplify = {
  "notification-view-details": "查看详情",
  "none-notification-title": "暂无消息",
  "none-notification-description": "暂时没有消息，请稍后查看。",
  "notification-data-redemption-completed-people-message": `共使用 {0} 个积分兑换了 {1} 个联络人。`,
  "notification-services-status-update-message": `服务请求状态更改为 {0}`,
  "notification-receive-referral-credit-bonus-message":
    "已获得 {0} 推荐积分，请继续努力！",
  notification_read_less: "收起",
  notification_read_more: "展开",
  notification_column_name_content: '消息',
  notification_column_name_date: '接收时间'
};

export const notificationTraditional = {
  "notification-view-details": "查看詳情",
  "none-notification-title": "暫無消息",
  "none-notification-description": "暫時沒有消息，請稍候查看。",
  "notification-data-redemption-completed-people-message": `總共 {0} 個信用點已用於兌換 {1} 個聯絡人。`,
  "notification-services-status-update-message": `服務請求狀態變更為 {0}`,
  "notification-receive-referral-credit-bonus-message":
    "已獲得 {0} 推薦信用點，請繼續努力！",
  notification_read_less: "收起",
  notification_read_more: "展開",
  notification_column_name_content: '消息',
  notification_column_name_date: '接收時間'
};
