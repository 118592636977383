import router from "@/router";
import store from "@/store";
import axios from "axios";
import { Message } from "element-ui";
import {
  ERR_ALREADY_EXISTS,
  ERR_BAD_REQUEST,
  ERR_MAX_QUERY,
  ERR_NOT_FOUND,
  ERR_TOO_MANY_REQUEST,
  ERR_UNAUTHORIZED,
  ERR_UPGRADE_REQUIRED,
} from "./err";

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  headers: {
    "Content-Type": "application/json",
  },
});

// 请求拦截器
request.interceptors.request.use(
  (config) => {
    if (store.state.user.token) {
      config.headers["Authorization"] = `Bearer ${store.state.user.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
request.interceptors.response.use(
  (response) => {
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return Promise.reject(new Error(`HTTP Error: ${status}`));
    }
  },
  (error) => {
    console.log(error);
    if (error.response) {
      switch (error.response.status) {
        case ERR_UNAUTHORIZED:
          Message.error({
            message: "Unauthorized, please login again",
            duration: 1500,
            forbidClick: true,
          });
          store.commit("user/removeToken");
          store.commit("user/removeApikey");
          setTimeout(() => {
            router.replace({
              path: "/login",
              query: {
                redirect: router.currentRoute.fullPath,
              },
            });
          }, 1500);
          break;
        case ERR_NOT_FOUND:
          Message.error({
            message: "Network request does not exist",
            duration: 1500,
            forbidClick: true,
          });
          break;
        case ERR_BAD_REQUEST:
        case ERR_TOO_MANY_REQUEST:
        case ERR_ALREADY_EXISTS:
        case 524:
        case 409: // 目前只有大类，报too many request
          Message.error({
            message: error.response?.data?.message,
            duration: 2000,
            forbidClick: true,
          });
          break;
        case 402:
          // return Promise.reject(error)
          if (error.response.data.code === 40208) {
            store.commit("common/setPaymentSecurityDialog", true);
          }
          if (error.response.data.code === 40201) {
            store.commit('common/setPayDialog', true)
          }
          break;
        case 406:
          if (error.response.data.code === 40606) {
            store.commit("common/setNotEnoughCredits", true);
          }
          break;
        case ERR_MAX_QUERY:
          store.commit("common/setMaximumQueryLimitShow");
          break;
        case ERR_UPGRADE_REQUIRED:
          break;
      }
    } else {
      if (error.code === "ERR_NETWORK") {
        Message.error({
          message: 'Network Error',
          duration: 2000,
          forbidClick: true,
        });
      }
      // return Promise.reject(error);
    }
    throw error;
  }
);

export default request;
