<template>
  <el-dialog
    :visible.sync="notEnoughCredits"
    :modal-append-to-body="false"
    :before-close="handleClose"
    width="519px"
    custom-class="pay-tip-dialog"
    :close-on-click-modal="false"
  >
    <div class="pay-dialog-close-wrapper" @click="handleClose">
      <svg-icon icon-class="pay-dialog-close" class="pay-dialog-close" />
    </div>
    <div class="title">
      <svg-icon icon-class="lead-limit-tip" class="icon-danger" />
      <span>You Don’t Have Enough Credits</span>
    </div>
    <div>
      <!-- <div class="content1">This action requires 1 credit.</div> -->
      <div class="content2">Would you like to add {{systemConfig.min_credit_topup}} credits (the minimum recharge amount) to proceed with sending the email?</div>
    </div>
    <div class="autoup-checkbox">
      <el-checkbox v-model="autoTopup" @change="handleTopup">
        <span style="margin-right: 8px">{{ $t("enableAutoTopups") }}</span>
        <el-popover
          placement="top"
          width="417"
          popper-class="tip-popover"
          trigger="hover"
        >
          <div>
            {{ $t("autoTopupWillAutomaticallyChargeYourPaymentMethodT") }}
          </div>
        </el-popover>
      </el-checkbox>
    </div>
    <div class="btns-wrapper">
      <el-button @click="handleClose" class="btn-group-1">{{ $t('Cancel') }}</el-button>
      <el-button @click="topUpNow" class="btn-group-2">
        Add Credits
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { updateProfile } from "@/api/user";

export default {
  data() {
    return {
      autoTopup: false,
    }
  },
  computed: {
    ...mapState('common', ['notEnoughCredits']),
    ...mapState("user", ["systemConfig", 'currentId']),
  },
  methods: {
    ...mapMutations('common', ['setNotEnoughCredits']),
    ...mapActions('common', ['topUp']),
    handleClose() {
      this.setNotEnoughCredits(false);
    },
    handleTopup(val) {
      updateProfile({
        profile_id: this.currentId.profile_id,
        is_auto_top_up_credits_enabled: val,
      }).then(res => {

      }).finally(() => {
        this.setNotEnoughCredits(false);
      })
    },
    topUpNow() {
      this.setNotEnoughCredits(false);
      this.topUp({
          info: {
            path: this.$route.path
          },
          recall: false,
          cb: () => {
            let params;
            if (this.$route.path === '/search/people') {
              params = {
                page: this.$route.path,
                name: 'setSelected',
                params: {
                  ...this.$store.state.people.selected,
                  notReset: true,
                },
              }
            } if (this.$route.path === '/search/company') {
              params = {
                page: this.$route.path,
                name: 'setSelected',
                params: {
                  ...this.$store.state.company.selected,
                  notReset: true,
                },
              }
            }
            localStorage.setItem('CbApiInfo2', JSON.stringify(params));
          }
      });
      },
  }
}
</script>

<style scoped lang='scss'>
.autoup-checkbox {
  margin-top: 24px;
}
</style>
