<template>
  <div class="dashboard" >
    <NavBar />
    <keep-alive exclude="intent">
      <router-view />
    </keep-alive>
    <MaximumQueryPopup />
    <NotEnoughCredits />
    <PaymentSecurityPopup />
    <AddCard />
  </div>
</template>

<script>
import NavBar from "./components/NavBar";
import MaximumQueryPopup from './components/MaximumQueryPopup.vue';
import NotEnoughCredits from './components/NotEnoughCredits.vue';
import PaymentSecurityPopup from './components/PaymentSecurityPopup.vue';
import AddCard from './components/AddCard.vue';

export default {
  name: "Dashboard",
  components:{
    NavBar,
    MaximumQueryPopup,
    NotEnoughCredits,
    PaymentSecurityPopup,
    AddCard
  },
  data(){
    return{
      isDashboard: true,
    }
  },
  methods:{

  }
}

</script>

<style scoped lang="scss">
.dashboard{
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height:100vh;
}
</style>



